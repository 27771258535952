.AboutMe {
    display: flex;
    flex-direction: column;
    background-color: rgb(27, 27, 27);;
    width: 100vw;
    height: 100vh;
}

.AboutMeTitle {
    cursor: default;
    display: flex;
    width: 100vw;
    justify-content: center;
    padding-top: 5rem;
    padding-bottom: 3.5rem;
    align-items: flex-end;
    font-size: 5rem;
    font-weight: bold;
    color: rgb(236, 38, 80);
}

.AboutMeContent {
    display: flex;
    flex-direction: row;
    margin-left: 12rem;
    margin-right: 12rem;
}

.AboutMePicture {
    border-radius: 50%;
    height: 25rem;
    width: 25rem;
    transition: transform .75s ease-in-out;
    margin-right: 4rem;
    margin-left: 0rem;
}

.AboutMePicture:hover {
    transform: rotate(360deg);
}

.AboutMeText {
    cursor: default;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: left;
    margin-left: 4rem;
}

.AboutMeText h2 {
    font-size: 3rem;
    margin-bottom: 1.5rem;
}

.AboutMeText p {
    font-weight: 600;
    margin-bottom: 1.5rem;
}
