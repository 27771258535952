.NavBar {
    height: 4rem;
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    position: fixed;
    top: 0;
    z-index: 3;
}

.DesktopNavMenuItem {
    cursor: pointer;
    color: whitesmoke;
    font-weight: bold;
    font-size: larger;
    position: relative;
    transition: color .285s ease-in-out;
} 
	
.DesktopNavMenuItem::before {
	content: '';
	position: absolute;
	top: 130%;
	width: 100%;
	height: 0.25rem;
	background-color: rgb(236, 38, 80);
	transform: scaleX(0);
	transition: transform .25s ease-in-out;
}
	
.DesktopNavMenuItem:hover {
    color: rgb(236, 38, 80);
}
	
.DesktopNavMenuItem:hover::before {
	transform: scaleX(1);
}	

  