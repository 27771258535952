.HomePage {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: 100vh;

}

.HomeBackground {
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background-image: url("../assets/4k_triple_black_waves.png");
    background-size: cover;
    position: fixed;
    z-index: -1;
}

.HelloText {
    font-size: 8rem;
    color: whitesmoke;
    margin: 2rem;
    cursor: default;
}

.MyName {
    font-size: 8rem;
    color: rgb(236, 38, 80);
    cursor: default;
}


  