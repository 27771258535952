.ExtraStuff {
    display: flex;
    flex-direction: column;
    background-color: rgb(27, 27, 27);;
    width: 100vw;
    height: 100vh;
}

.ExtraStuffTitle {
    cursor: default;
    display: flex;
    width: 100vw;
    justify-content: center;
    padding-top: 5rem;
    padding-bottom: 3.5rem;
    align-items: flex-end;
    font-size: 5rem;
    font-weight: bold;
    color: rgb(236, 38, 80);
}

.ExtraStuffContent {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

@keyframes spin {
    from {transform:rotate(0deg);}
    to {transform:rotate(360deg);}
}

.ConstructionImg {
    height: 25rem;
    width: 25rem;
    animation: spin 5s linear infinite;
}

.ExtraStuffContent span {
    padding-top: 3.5rem;
    font-size: 2rem;
    font-weight: 600;
}