.Projects {
    display: flex;
    flex-direction: column;
    background-color: rgb(27, 27, 27);
    width: 100vw;
    height: max-content;
}

.ProjectsTitle {
    cursor: default;
    display: flex;
    width: 100vw;
    justify-content: center;
    padding-top: 5rem;
    padding-bottom: 3rem;
    align-items: flex-end;
    font-size: 5rem;
    font-weight: bold;
    color: rgb(236, 38, 80);
}

.ProjectsContent {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.CardContainer {
    position: relative;
    width: 45rem;
    height: 22.5rem;
    margin-bottom: 4rem;
}

.ProjectCard {
    position: absolute;
    width: 100%;
    height: 100%;
    transform-style: preserve-3d;
    transition: all 0.75s ease-in-out;
}

.ProjectCard:hover {
    transform: rotateY(180deg);
}

.WebsiteCardFront {
    cursor: default;
    position: absolute;
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
    color: whitesmoke;
    background-image: url("../assets/4k_red_waves.png");
    background-size: cover;
    border-radius: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 2.5rem;
    font-weight: bold;
    text-shadow: black 3px 3px 5px;
}

.WebsiteCardBack {
    position: absolute;
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
    color: whitesmoke;
    border-radius: 1rem;
    transform: rotateY(180deg);
    background: rgb(16, 16, 16);
    display: flex;
    flex-direction: column;
    align-items: center;
}

.WebsiteCardBack h2 {
    font-size: 2rem;
    margin-top: 2rem;
    margin-bottom: 1.5rem;
    cursor: default;
}

.WebsiteCardBack p {
    cursor: default;
    margin-left: 2rem;
    margin-right: 2rem;
    margin-bottom: 2rem;
    text-align: left;
    font-weight: 600;
}

.WebsiteCardBack img {
    cursor: pointer;
    transition: all 0.2s ease-in-out;
}

.WebsiteCardBack img:hover {
    transform: scale(1.1);
}

.BoggleCardFront {
    cursor: default;
    position: absolute;
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
    color: whitesmoke;
    background-image: url("../assets/boggle-bg.png");
    background-size: cover;
    border-radius: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 2.5rem;
    font-weight: bold;
    text-shadow: black 3px 3px 5px;
}

.BoggleCardBack {
    position: absolute;
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
    color: whitesmoke;
    border-radius: 1rem;
    transform: rotateY(180deg);
    background: rgb(16, 16, 16);
    display: flex;
    flex-direction: column;
    align-items: center;
}

.BoggleCardBack h2 {
    font-size: 2rem;
    margin-top: 2rem;
    margin-bottom: 1.5rem;
    cursor: default;
}

.BoggleCardBack p {
    cursor: default;
    margin-left: 2rem;
    margin-right: 2rem;
    text-align: left;
    font-weight: 600;
}

.BoggleCardBack .BoggleLink {
    color: rgb(236, 38, 80);
}

.BoggleCardBack img {
    height: 6rem;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
}

.BoggleCardBack img:hover {
    transform: scale(1.1);
}

.EWasteCardFront {
    cursor: default;
    position: absolute;
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
    color: whitesmoke;
    background-image: url("../assets/e-waste_bg.png");
    background-size: cover;
    border-radius: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 2.5rem;
    font-weight: bold;
    text-shadow: black 3px 3px 5px;
}

.EWasteCardBack {
    position: absolute;
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
    color: whitesmoke;
    border-radius: 20px;
    transform: rotateY(180deg);
    background: rgb(16, 16, 16);
    display: flex;
    flex-direction: column;
    align-items: center;
}

.EWasteCardBack h2 {
    font-size: 0.75cm;
    margin-top: 2rem;
    margin-bottom: 1.5rem;
    cursor: default;
}

.EWasteCardBack p {
    cursor: default;
    margin-left: 2rem;
    margin-right: 2rem;
    margin-bottom: 1rem;
    text-align: left;
    font-weight: 600;
}

.EWasteCardBack img {
    cursor: pointer;
    transition: all 0.2s ease-in-out;
}

.EWasteCardBack img:hover {
    transform: scale(1.1);
}